import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable()
export class TokenService {
  constructor(private http: HttpClient) {}

  public getBearerToken() {
    const url = '/oauth/bearer/gqe';

    const bearerToken = localStorage.getItem('bearerToken');
    if (bearerToken) {
      return of(bearerToken);
    }

    return this.http.get(url).pipe(
      map((response: Response) => {
        localStorage.setItem('bearerToken', response.body['data']);
        return response.body['data'];
      })
    );
  }
}
