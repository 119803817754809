<!-- Preloader -->
<div class="spinner-wrapper">
  <div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div>

<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
  <div class="container">
    <!-- Text Logo - Use this if you don't have a graphic logo -->
    <a class="navbar-brand logo-text page-scroll" href="index.html">Saasi</a>

    <!-- Image Logo -->
    <!--a class="navbar-brand logo-image" href="index.html"><img src="../assets/theme/images/logo.svg" alt="alternative"></a-->

    <!-- Mobile Menu Toggle Button -->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarsExampleDefault"
      aria-controls="navbarsExampleDefault"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-awesome fas fa-bars"></span>
      <span class="navbar-toggler-awesome fas fa-times"></span>
    </button>
    <!-- end of mobile menu toggle button -->

    <div class="collapse navbar-collapse" id="navbarsExampleDefault">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link page-scroll" href="#header"
            >Home <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link page-scroll" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link page-scroll" href="#details">Details</a>
        </li>
        <li class="nav-item">
          <a class="nav-link page-scroll" href="#pricing">Pricing</a>
        </li>
      </ul>
      <span class="nav-item">
        <a class="btn-outline-sm" href="#getstarted">Get Started</a>
      </span>
    </div>
  </div>
  <!-- end of container -->
</nav>
<!-- end of navbar -->
<!-- end of navigation -->

<!-- Header -->
<header id="header" class="header">
  <div class="header-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <h1>Ready for your restaurant to thrive?</h1>
            <p class="p-large">
              We understand that you have a lot on your hands running a
              restaurant. Now more than ever a strong online presence is vital.
              Thus, you need an easy, convenient and affordable plan for all
              your digital marketing needs. Increase your profits and waste no
              time with the all-in-one solution: SAASI!
            </p>
            <a class="btn-solid-lg page-scroll" href="#getstarted">SIGN UP</a>
          </div>
          <!-- end of text-container -->
        </div>
        <!-- end of col -->
        <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <div class="img-wrapper">
              <img
                class="img-fluid"
                src="../assets/theme/images/header-software-app.png"
                alt="alternative"
              />
            </div>
            <!-- end of img-wrapper -->
          </div>
          <!-- end of image-container -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of header-content -->
</header>
<!-- end of header -->
<svg
  class="header-frame"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="none"
  viewBox="0 0 1920 310"
>
  <defs>
    <style>
      .cls-1 {
        fill: #10d48e;
      }
    </style>
  </defs>
  <title>header-frame</title>
  <path
    class="cls-1"
    d="M0,283.054c22.75,12.98,53.1,15.2,70.635,14.808,92.115-2.077,238.3-79.9,354.895-79.938,59.97-.019,106.17,18.059,141.58,34,47.778,21.511,47.778,21.511,90,38.938,28.418,11.731,85.344,26.169,152.992,17.971,68.127-8.255,115.933-34.963,166.492-67.393,37.467-24.032,148.6-112.008,171.753-127.963,27.951-19.26,87.771-81.155,180.71-89.341,72.016-6.343,105.479,12.388,157.434,35.467,69.73,30.976,168.93,92.28,256.514,89.405,100.992-3.315,140.276-41.7,177-64.9V0.24H0V283.054Z"
  />
</svg>
<!-- end of header -->

<div class="basic-1">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="text-container">
          <h2>Why choose SAASI?</h2>
          <ul class="list-unstyled li-space-lg">
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                Comprehensive plans starting at $30 a month
              </div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                Can be used alongside your existing services or websites
              </div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                Uses white label tactics to enhance your restaurant's brand
              </div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                The digital ordering system is commission free
              </div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                Set up recurring campaigns and loyalty programs quickly
              </div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                Capture daily cleaning checklist for auditing purpose
              </div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">And more!</div>
            </li>
          </ul>
        </div>
        <!-- end of text-container -->
      </div>
      <!-- end of col -->
      <div class="col-lg-6">
        <div class="image-container">
          <img
            class="img-fluid"
            src="../assets/theme/images/why_saasi.png"
            alt="alternative"
          />
        </div>
        <!-- end of image-container -->
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of basic-1 -->

<!-- Features -->
<div id="features" class="tabs">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="above-heading">FEATURES</div>
        <h2 class="h2-heading">What do you get with SASSI?</h2>
        <p class="p-heading">
          Saasi offers everything you need to grow your restaurant’s online
          presence! Set up is fast, managing is easy and you’ll be in full
          control of your marketing. Start your new business off strong with
          these amazing features! Already have an established restaurant and
          website? No problem! Saasi can be integrated into your existing
          services with zero commissions. Read on to learn more about all the
          incredible ways Saasi is the best next step for your business!
        </p>
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
    <div class="row">
      <div class="col-lg-12">
        <!-- Tabs Links -->
        <ul class="nav nav-tabs" id="argoTabs" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="nav-tab-1"
              data-toggle="tab"
              href="#tab-1"
              role="tab"
              aria-controls="tab-1"
              aria-selected="true"
              ><i class="fas fa-list"></i>Instant Set Up</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="nav-tab-2"
              data-toggle="tab"
              href="#tab-2"
              role="tab"
              aria-controls="tab-2"
              aria-selected="false"
              ><i class="fas fa-envelope-open-text"></i>Easy Management</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="nav-tab-3"
              data-toggle="tab"
              href="#tab-3"
              role="tab"
              aria-controls="tab-3"
              aria-selected="false"
              ><i class="fas fa-credit-card"></i>Payment Processing</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="nav-tab-4"
              data-toggle="tab"
              href="#tab-4"
              role="tab"
              aria-controls="tab-4"
              aria-selected="false"
              ><i class="fas fa-chart-bar"></i>Full Marketing Control</a
            >
          </li>
        </ul>
        <!-- end of tabs links -->

        <!-- Tabs Content -->
        <div class="tab-content" id="argoTabsContent">
          <!-- Tab -->
          <div
            class="tab-pane fade show active"
            id="tab-1"
            role="tabpanel"
            aria-labelledby="tab-1"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="image-container">
                  <img
                    class="img-fluid"
                    src="../assets/theme/images/features-1.png"
                    alt="alternative"
                  />
                </div>
                <!-- end of image-container -->
              </div>
              <!-- end of col -->
              <div class="col-lg-6">
                <div class="text-container">
                  <h3>Dynamic Website Generation</h3>
                  <p>
                    Get your restaurant online quickly without a drop in
                    quality! It takes 3 easy steps to setup:
                  </p>
                  <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Choose a template and color theme.
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Preview your website in desktop, tablet and mobile
                        views.
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Link your existing social media and delivery partners.
                      </div>
                    </li>
                  </ul>
                  <p>
                    And with that your website is live and running! Couldn’t be
                    simpler!
                  </p>
                  <p>
                    Better still, you can handle your hosting needs with SAASI
                    too! Domains and subdomains include...
                  </p>
                  <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">Secured SSL (https)</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">24/7 support</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">SEO optimization</div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">0 downtime</div>
                    </li>
                  </ul>
                  <p>
                    Get your domain for a minimal cost or your subdomain for
                    free when you sign up today!
                  </p>
                  <p>
                    Already have a website? We can have the SAASI online
                    ordering integrated into your existing website and social
                    media platforms.
                  </p>
                </div>
                <!-- end of text-container -->
              </div>
              <!-- end of col -->
            </div>
            <!-- end of row -->
          </div>
          <!-- end of tab-pane -->
          <!-- end of tab -->

          <!-- Tab -->
          <div
            class="tab-pane fade"
            id="tab-2"
            role="tabpanel"
            aria-labelledby="tab-2"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="image-container">
                  <img
                    class="img-fluid"
                    src="../assets/theme/images/features-2.png"
                    alt="alternative"
                  />
                </div>
                <!-- end of image-container -->
              </div>
              <!-- end of col -->
              <div class="col-lg-6">
                <div class="text-container">
                  <h3>Instant Digital Menu & Seamless Ordering Management</h3>
                  <p>
                    Online ordering is vital in the current restaurant industry!
                    Our QR code digital menu is contactless, paperless and can
                    be generated for you with little human effort required.
                    SAASI can improve table turn times, control take out margins
                    and get you set up for pickup or on-demand delivery. Best
                    part? It's available, commission free!
                  </p>
                  <p>
                    Take your branding and online services even further with a
                    mobile app! At a low cost you can have a fully branded
                    mobile app in around 2 weeks from signing up.
                  </p>
                  <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Seamless Order Management: Receive contactless,
                        paperless orders online in a way that seamlessly fits
                        into the management of your restaurant. This service
                        includes the ability to automate email confirmations,
                        SMS and fax alerts. Coming soon you will also be able to
                        have remote, wireless kitchen printing with your
                        in-house Star printer!
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Online Payment Options: With online ordering you will
                        also want online payment options so customers don’t have
                        to pay in person. Pair online credit card or PayPal
                        options with your current POS system for easy and
                        convenient management.
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Coupons, Discounts and Deals: You can integrate Saasi
                        with GrabQpons for effective and simple management of
                        coupons, discounts, deals and more! With this system you
                        can:
                        <ul class="list-unstyled li-space-lg">
                          <li class="media">
                            <i class="fas fa-square"></i>
                            <div class="media-body">
                              Launch real-time campaigns to attract business
                              during slow hours
                            </div>
                          </li>
                          <li class="media">
                            <i class="fas fa-square"></i>
                            <div class="media-body">
                              Set up recurring discounts and coupons.
                            </div>
                          </li>
                          <li class="media">
                            <i class="fas fa-square"></i>
                            <div class="media-body">
                              Track coupon claims, customer retention and
                              trending deals.
                            </div>
                          </li>
                          <li class="media">
                            <i class="fas fa-square"></i>
                            <div class="media-body">
                              Supports restaurant managed delivery partners.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <a
                    class="btn-solid-reg popup-with-move-anim"
                    href="#details-lightbox-2"
                    >LIGHTBOX</a
                  >
                </div>
                <!-- end of text-container -->
              </div>
              <!-- end of col -->
            </div>
            <!-- end of row -->
          </div>
          <!-- end of tab-pane -->
          <!-- end of tab -->

          <!-- Tab -->
          <div
            class="tab-pane fade"
            id="tab-3"
            role="tabpanel"
            aria-labelledby="tab-3"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="image-container">
                  <img
                    class="img-fluid"
                    src="../assets/theme/images/features-3.png"
                    alt="alternative"
                  />
                </div>
                <!-- end of image-container -->
              </div>
              <!-- end of col -->
              <div class="col-lg-6">
                <div class="text-container">
                  <h3>Payment Processing</h3>
                  <p>
                    Manage payments and tips your way! Our software can accept
                    all payment types from quickly and securely. Promoted
                    gratuity will drive higher tip averages regardless if
                    tableside, at the counter, online or on-the-go! You will
                    also get free notifications in the method you prefer
                    including email, SMS or fax. Coming soon you’ll be able to
                    get ordering alerts connected to your remote Star wireless
                    kitchen printer!
                  </p>
                </div>
                <!-- end of text-container -->
              </div>
              <!-- end of col -->
            </div>
            <!-- end of row -->
          </div>
          <!-- end of tab-pane -->
          <!-- end of tab -->

          <!-- Tab -->
          <div
            class="tab-pane fade"
            id="tab-4"
            role="tabpanel"
            aria-labelledby="tab-4"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="image-container">
                  <img
                    class="img-fluid"
                    src="../assets/theme/images/features-4.png"
                    alt="alternative"
                  />
                </div>
                <!-- end of image-container -->
              </div>
              <!-- end of col -->
              <div class="col-lg-6">
                <div class="text-container">
                  <h3>Real-Time Marketing Campaigns</h3>
                  <p>
                    We provide a way to boost sales with well-informed and
                    smartly executed campaigns! Have access to rich customer
                    insights with automatic data gathering so you can leverage
                    your promotions and coupons to your advantage. Meanwhile you
                    can also establish loyalty programs for repeating customers
                    quickly and easily. Features include…
                  </p>
                  <ul class="list-unstyled li-space-lg">
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Convenient integration with GrabQpons, the premium
                        coupons management platform
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Publish coupons and discounts during off-peak hours to
                        generate more revenue
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Setup recurring (daily, weekly or monthly) campaigns to
                        maximize profits
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Track coupon claims and orders in real-time
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Gather data on repeated and lost customers
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Keep an eye on trending deals published by competitors
                      </div>
                    </li>
                    <li class="media">
                      <i class="fas fa-square"></i>
                      <div class="media-body">
                        Fully supportive of your restaurant managed delivery
                        partners
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- end of text-container -->
              </div>
              <!-- end of col -->
            </div>
            <!-- end of row -->
          </div>
          <!-- end of tab-pane -->
          <!-- end of tab -->
        </div>
        <!-- end of tab content -->
        <!-- end of tabs content -->
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of tabs -->
<!-- end of features -->

<!-- Details Lightbox 1 -->
<div id="details-lightbox-1" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="container">
    <div class="row">
      <button title="Close (Esc)" type="button" class="mfp-close x-button">
        ×
      </button>
      <div class="col-lg-8">
        <div class="image-container">
          <img
            class="img-fluid"
            src="../assets/theme/images/details-lightbox.png"
            alt="alternative"
          />
        </div>
        <!-- end of image-container -->
      </div>
      <!-- end of col -->
      <div class="col-lg-4">
        <h3>List Building</h3>
        <hr />
        <h5>Core service</h5>
        <p>
          It's very easy to start using Tivo. You just need to fill out and
          submit the Sign Up Form and you will receive access to the app.
        </p>
        <ul class="list-unstyled li-space-lg">
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">List building framework</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Easy database browsing</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">User administration</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Automate user signup</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Quick formatting tools</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Fast email checking</div>
          </li>
        </ul>
        <a class="btn-solid-reg mfp-close" href="sign-up.html">SIGN UP</a>
        <a class="btn-outline-reg mfp-close as-button" href="#screenshots"
          >BACK</a
        >
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of lightbox-basic -->

<!-- Details Lightbox 2 -->
<div id="details-lightbox-2" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="container">
    <div class="row">
      <button title="Close (Esc)" type="button" class="mfp-close x-button">
        ×
      </button>
      <div class="col-lg-8">
        <div class="image-container">
          <img
            class="img-fluid"
            src="../assets/theme/images/details-lightbox.png"
            alt="alternative"
          />
        </div>
        <!-- end of image-container -->
      </div>
      <!-- end of col -->
      <div class="col-lg-4">
        <h3>Campaign Monitoring</h3>
        <hr />
        <h5>Core service</h5>
        <p>
          It's very easy to start using Tivo. You just need to fill out and
          submit the Sign Up Form and you will receive access to the app.
        </p>
        <ul class="list-unstyled li-space-lg">
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">List building framework</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Easy database browsing</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">User administration</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Automate user signup</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Quick formatting tools</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Fast email checking</div>
          </li>
        </ul>
        <a class="btn-solid-reg mfp-close" href="sign-up.html">SIGN UP</a>
        <a class="btn-outline-reg mfp-close as-button" href="#screenshots"
          >BACK</a
        >
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of lightbox-basic -->

<!-- Details Lightbox 3 -->
<div id="details-lightbox-3" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="container">
    <div class="row">
      <button title="Close (Esc)" type="button" class="mfp-close x-button">
        ×
      </button>
      <div class="col-lg-8">
        <div class="image-container">
          <img
            class="img-fluid"
            src="../assets/theme/images/details-lightbox.png"
            alt="alternative"
          />
        </div>
        <!-- end of image-container -->
      </div>
      <!-- end of col -->
      <div class="col-lg-4">
        <h3>Analytics Tools</h3>
        <hr />
        <h5>Core service</h5>
        <p>
          It's very easy to start using Tivo. You just need to fill out and
          submit the Sign Up Form and you will receive access to the app.
        </p>
        <ul class="list-unstyled li-space-lg">
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">List building framework</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Easy database browsing</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">User administration</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Automate user signup</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Quick formatting tools</div>
          </li>
          <li class="media">
            <i class="fas fa-square"></i>
            <div class="media-body">Fast email checking</div>
          </li>
        </ul>
        <a class="btn-solid-reg mfp-close" href="sign-up.html">SIGN UP</a>
        <a class="btn-outline-reg mfp-close as-button" href="#screenshots"
          >BACK</a
        >
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of lightbox-basic -->

<!-- Details -->
<div id="details" class="basic-1">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="text-container">
          <h2>Free limited time offer!</h2>
          <p>
            With our Print or Share Marketing Library offer you will receive:
          </p>
          <ul class="list-unstyled li-space-lg">
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                An analysis of your Google listing with step-by-step
                recommendations on how to optimize it for the best results!
              </div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                A free video (less than 30 seconds) per month you can use for
                marketing your restaurant!
              </div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                Free holiday banners that you can use for social media promotion
                for every major holiday!
              </div>
            </li>
          </ul>
          <p>Take advantage of this deal while it still lasts!</p>
          <a class="btn-solid-reg page-scroll" href="sign-up.html">SIGN UP</a>
        </div>
        <!-- end of text-container -->
      </div>
      <!-- end of col -->
      <div class="col-lg-6">
        <div class="image-container">
          <img
            class="img-fluid"
            src="../assets/theme/images/discount.png"
            alt="alternative"
          />
        </div>
        <!-- end of image-container -->
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of basic-1 -->
<!-- end of details -->


<div id="pricing" class="cards-2">
  <div class="container ">
    <div class="row">
      <div class="col-lg-12">
        <div class="above-heading">PRICING</div>
        <h2 class="h2-heading">Subscription Plans</h2>
        <p>
          Our goal is to provide a software service that takes all the hard work
          out of getting your restaurant functioning online. From your online
          presence to coupons and order management - Saasi strives to be the
          marketing solution for new and established restaurants alike. We offer
          three plans to suit any business's needs.
        </p>
      </div>
    </div>
    <div class="phoneContent pricing-card">
      <div class="body">
          <div
          class="col-sm-12 col-md-4 col-lg-4"
         
        >
          
          <div class="card">
            <div class="card-body">
              <div class="card-title">Starter</div>
              <div class="price">
                <span class="currency">$</span
                ><span class="value">10.99</span>
              </div>
              <div class="frequency">a month</div>
              <div class="divider"></div>
              <ul class="list-unstyled li-space-lg">
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">Accept Unlimited Orders via Digital QR Code Menu Page with Google Reviews</div>
                </li>
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">Menu Management - Update, upsell Menu Items with discount setup</div>
                </li>
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">Accept Order Payment In Store or Online and receive SMS/FAX, Email Order Confirmations'</div>
                </li>
              </ul>
              
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-title">Rise</div>
              <div class="price">
                <span class="currency">$</span
                ><span class="value">39.99</span>
              </div>
              <div class="frequency">a month</div>
              <div class="divider"></div>
              <ul class="list-unstyled li-space-lg">
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">Starter plans + </div>
                </li>
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">Branded Website with Subdomain Hosting that links Delivery & Social Media channels</div>
                </li>
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">Run Daily/Weekly Campaigns without any design skills, instantly share Auto ad Images on social media channels</div>
                </li>
              </ul>
              
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-title">ADVANCED</div>
              <div class="price">
                <span class="currency">$</span
                ><span class="value">29.99</span>
              </div>
              <div class="frequency">a month</div>
              <div class="divider"></div>
              <ul class="list-unstyled li-space-lg">
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">Rise +</div>
                </li>
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">Email Marketing Module</div>
                </li>
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">User Control Management</div>
                </li>
                <li class="media" >
                  <i  class="fas fa-check"></i>
                 
                  <div class="media-body">List Building And Cleaning</div>
                </li>
              </ul>
              
            </div>
          </div>
          <!-- end of card -->
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-title">COMPLETE</div>
            <div class="price">
              <span class="currency">$</span
              ><span class="value">--</span>
            </div>
            <div class="frequency">a month</div>
            <div class="divider"></div>
            <ul class="list-unstyled li-space-lg">
              <li class="media" >
                <i  class="fas fa-check"></i>
               
                <div class="media-body">ADVANCED +</div>
              </li>
              <li class="media" >
                <i  class="fas fa-check"></i>
               
                <div class="media-body">Kitchen Printer Support (for Star models)</div>
              </li>
              <li class="media" >
                <i  class="fas fa-check"></i>
               
                <div class="media-body">Branded App Development, Merchant Account gateways Integration and On-demand delivery</div>
              </li>
              <li class="media" >
                <i  class="fas fa-check"></i>
               
                <div class="media-body">Social media Optimization & maintenance, Review Follow-ups & Video Marketing</div>
              </li>
            </ul>
            
          </div>
        </div>

        </div>
      </div>

    <div class="deskContent pricing-card">
      <div class="body">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <div style="font-size: 2rem" class="title">Starter</div>
                <img
                  style="max-width: 90px"
                  src="../assets/theme/images/helicopter.png"
                  alt="alternative"
                />
                <div class="price">
                  <span class="currency">$</span
                  ><span class="value">10.99</span>
                </div>
                <div class="frequency">a month</div>
                <div class="frequency">(Menu Infrastructure )</div>
              </th>
              <th
                class="active-plan"
                scope="col"
                style="border-top: 1px solid rgba(0, 0, 0, 0.125)"
              >
                <div style="font-size: 2rem" class="title">Rise</div>
                <img
                  style="max-width: 90px"
                  src="../assets/theme/images/shuttle.png"
                  alt="alternative"
                />
                <div class="price">
                  <span class="currency">$</span
                  ><span class="value">39.99</span>
                </div>
                <div class="frequency">a month</div>
                <div class="frequency">(Menu Infrastructure + Web Hosting + Instant Campaigns)</div>
              </th>
              <th scope="col">
                <div style="font-size: 2rem" class="title">Scale</div>
                <img
                  style="max-width: 90px"
                  src="../assets/theme/images/satellite.png"
                  alt="alternative"
                />
                <div class="price">
                  <span class="currency">$</span
                  ><span class="value">59.99</span>
                </div>
                <div class="frequency">a month</div>
                <div class="frequency">(Menu Infrastructure +   Web Hosting + Instant  Campaigns + Loyalty )
                </div>
              </th>
              <th scope="col">
                <div class="title">Enterprise</div>
                <div class="price">
                  <span class="currency">Call for pricing</span
                  ><span class="value"></span>
                </div>

                <div class="frequency">(Menu Infrastructure +   Web Hosting + Instant Campaigns + Loyalty +Kitchen Printing(Star) + App Development + Social Media  Marketing & More!)
</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Accept Unlimited Orders via Digital QR Code Menu Page with Google Reviews
                </div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">Menu Management - Update, upsell Menu Items with discount setup</div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Accept Order Payment In Store or Online and receive SMS/FAX, Email Order Confirmations
                </div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Branded Website with Subdomain Hosting that links Delivery & Social Media channels
                </div>
              </th>
              <td></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Run Daily/Weekly Campaigns without any design skills, instantly share Auto ad Images on social media channels
                </div>
              </th>
              <td></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">Capture & Upload Manager Shift Change Checklist on Daily basis for Auditing. (Service aisle, Dining room etc) </div>
              </th>
              <td></td>
              <td class="active-plan">
                
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Loyalty/Elite Programs for Top & Lost Customers based on Monthly/Weekly Analytics  
                </div>
              </th>
              <td></td>
              <td class="active-plan">
                
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row"><div class="media-body">Cloud/Gost Kitchen & Multiple virtual locations for food trucks</div></th>
              <td></td>
              <td class="active-plan">
               
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">Kitchen Printer Support (for Star models)</div>
              </th>
              <td></td>
              <td class="active-plan">
               
              </td>
              <td></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Branded App Development, Merchant Account gateways Integration and On-demand delivery 
                </div>
              </th>
              <td></td>
              <td class="active-plan">
                
              </td>
              <td></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Social media Optimization & maintenance, Review Follow-ups & Video Marketing
                </div>
              </th>
              <td></td>
              <td class="active-plan">
               
              </td>
              <td></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <!-- <tr>
              <th scope="row">
                <div class="media-body">Customer data analytics</div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">Loyalty programs</div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Multiple virtual locations for food trucks
                </div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Kitchen printer support (for Star models)
                </div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">Branded app development</div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">
                  Merchant account gateways integration
                </div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">On-demand delivery</div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">Social media maintenance</div>
              </th>
              <td><i class="fas fa-check icon-active"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row">
                <div class="media-body">Review follow-ups</div>
              </th>
              <td><i class="fas fa-check"></i></td>
              <td class="active-plan">
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr>
            <tr>
              <th scope="row"><div class="media-body">Video marketing</div></th>
              <td><i class="fas fa-check"></i></td>
              <td
                class="active-plan"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.125)"
              >
                <i class="fas fa-check icon-active"></i>
              </td>
              <td><i class="fas fa-check icon-active"></i></td>
              <td><i class="fas fa-check icon-active"></i></td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- getstarted -->
<div id="getstarted" class="cards-2">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="above-heading">Get Started</div>
        <h2 class="h2-heading">Ready to sign up for SAASI?</h2>
        <p>
          You can use this convenient setup wizard to get started. You will need
          to have your restaurant’s info, any existing social media or website
          links, and payment information to get through the process. If you have
          all the information on hand then the setup should take less than 10
          minutes!
        </p>
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
    <div class="row">
      <div class="col-lg-12">
        <app-home class="mat-typography"></app-home>
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of cards-2 -->
<!-- end of getstarted -->

<!-- Testimonials -->
<div class="slider-2">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <!-- Text Slider -->
        <div class="slider-container">
          <div class="swiper-container text-slider">
            <div class="swiper-wrapper">
              <!-- Slide -->
              <div class="swiper-slide">
                <div class="image-wrapper">
                  <img
                    class="img-fluid"
                    src="../assets/theme/images/testimonial-1.jpg"
                    alt="alternative"
                  />
                </div>
                <!-- end of image-wrapper -->
                <div class="text-wrapper">
                  <div class="testimonial-text">
                    I love how fast it was to get SAASI! And their slow-hours coupon marketing has really upped business! I couldn’t be happier!
                  </div>
                  <div class="testimonial-author">
                    Rebecca Forrest - Cafe Zen - NYC
                  </div>
                </div>
                <!-- end of text-wrapper -->
              </div>
              <!-- end of swiper-slide -->
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <div class="image-wrapper">
                  <img
                    class="img-fluid"
                    src="../assets/theme/images/testimonial-2.jpg"
                    alt="alternative"
                  />
                </div>
                <!-- end of image-wrapper -->
                <div class="text-wrapper">
                  <div class="testimonial-text">
                    I make a mean pizza but don’t know the first thing about online marketing. SAASI made it so easy to get set up! It’s unbelievable how quickly I got a website, online menu, and coupon deals for my restaurant!
                    focused on results.
                  </div>
                  <div class="testimonial-author">Nathan Smith - Pizza Divine - Cambria, CA</div>
                </div>
                <!-- end of text-wrapper -->
              </div>
              <!-- end of swiper-slide -->
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <div class="image-wrapper">
                  <img
                    class="img-fluid"
                    src="../assets/theme/images/testimonial-3.jpg"
                    alt="alternative"
                  />
                </div>
                <!-- end of image-wrapper -->
                <div class="text-wrapper">
                  <div class="testimonial-text">
                    I wish I had this service sooner! SAASI was set up to work perfectly with what we already had going. It has helped keep us organized and we are getting online orders no more than ever! Thank You SAASI!
                  </div>
                  <div class="testimonial-author">
                    Lee Hu - A Taste of China - Tallahassee, FL
                  </div>
                </div>
                <!-- end of text-wrapper -->
              </div>
              <!-- end of swiper-slide -->
              <!-- end of slide -->
            </div>
            <!-- end of swiper-wrapper -->

            <!-- Add Arrows -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            <!-- end of add arrows -->
          </div>
          <!-- end of swiper-container -->
        </div>
        <!-- end of slider-container -->
        <!-- end of text slider -->
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of slider-2 -->
<!-- end of testimonials -->

<!-- Newsletter -->
<div class="form">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-container">
          <div class="above-heading">NEWSLETTER</div>
          <h2>
            Get the latest news on how to boost online presence and give your
            customers the best possible experience!
          </h2>

          <!-- Newsletter Form -->
          <form id="newsletterForm" data-toggle="validator" data-focus="false">
            <div class="form-group">
              <input
                type="email"
                class="form-control-input"
                id="Newsletteremail"
                required
              />
              <label class="label-control" for="nemail">Email</label>
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group checkbox">
              <input
                type="checkbox"
                id="nterms"
                value="Agreed-to-Terms"
                required
              />I've read and agree to Tivo's written
              <a href="https://static.grabqpons.com/default/assets/terms/GQ-TermsConditions.pdf">Terms of Use</a> and
              <a href="https://static.grabqpons.com/default/assets/terms/GQ-PrivacyPolicy.pdf">Privacy Policy</a>
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <button (click)="subscribe()" type="submit" class="form-control-submit-button">
                SUBSCRIBE
              </button>
            </div>
            <div class="form-message">
              <div id="nmsgSubmit" class="h3 text-center hidden"></div>
            </div>
          </form>
          <!-- end of newsletter form -->
        </div>
        <!-- end of text-container -->
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="icon-container">
          <span class="fa-stack">
            <a href="#your-link">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fab fa-facebook-f fa-stack-1x"></i>
            </a>
          </span>
          <span class="fa-stack">
            <a href="#your-link">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fab fa-twitter fa-stack-1x"></i>
            </a>
          </span>
          <!-- span class="fa-stack">
            <a href="#your-link">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fab fa-pinterest-p fa-stack-1x"></i>
            </a>
          </span -->
          <span class="fa-stack">
            <a href="#your-link">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fab fa-instagram fa-stack-1x"></i>
            </a>
          </span>
          <span class="fa-stack">
            <a href="#your-link">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fab fa-linkedin-in fa-stack-1x"></i>
            </a>
          </span>
        </div>
        <!-- end of col -->
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of form -->
<!-- end of newsletter -->

<!-- Footer -->
<svg
  class="footer-frame"
  data-name="Layer 2"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="none"
  viewBox="0 0 1920 79"
>
  <defs>
    <style>
      .cls-2 {
        fill: #10d48e;
      }
    </style>
  </defs>
  <title>footer-frame</title>
  <path
    class="cls-2"
    d="M0,72.427C143,12.138,255.5,4.577,328.644,7.943c147.721,6.8,183.881,60.242,320.83,53.737,143-6.793,167.826-68.128,293-60.9,109.095,6.3,115.68,54.364,225.251,57.319,113.58,3.064,138.8-47.711,251.189-41.8,104.012,5.474,109.713,50.4,197.369,46.572,89.549-3.91,124.375-52.563,227.622-50.155A338.646,338.646,0,0,1,1920,23.467V79.75H0V72.427Z"
    transform="translate(0 -0.188)"
  />
</svg>
<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="footer-col first">
          <h4>About Saasi</h4>
          <p class="p-small">
            Saasi offers everything you need to grow your restaurant’s online presence !!
          </p>
        </div>
      </div>
      <!-- end of col -->
      <div class="col-md-4">
        <div class="footer-col middle">
          <h4>Partners</h4>
          <ul class="list-unstyled li-space-lg p-small">
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">
                Promotional Campaigns Management Partners
                <a class="white" href="www.grabqpons.com">www.grabqpons.com</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- end of col -->
      <div class="col-md-4">
        <div class="footer-col last">
          <h4>Contact</h4>
          <ul class="list-unstyled li-space-lg p-small">
            <!-- li class="media">
              <i class="fas fa-map-marker-alt"></i>
              <div class="media-body">
                22 Innovative, San Francisco, CA 94043, US
              </div>
            </li -->
            <li class="media">
              <i class="fas fa-envelope"></i>
              <div class="media-body">
                <a class="white" href="mailto:contact@gosassi.com">
                  contact@gosassi.io</a
                >
                <i class="fas fa-globe"></i
                ><a class="white" href="www.gosaasi.com">www.gosaasi.io</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- end of col -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of footer -->
<!-- end of footer -->

<!-- Copyright -->
<div class="copyright">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <p class="p-small">
          <a href="https://static.grabqpons.com/default/assets/terms/GQ-TermsConditions.pdf">Terms of Use</a> and
          <a href="https://static.grabqpons.com/default/assets/terms/GQ-PrivacyPolicy.pdf">Privacy Policy</a>
        </p>
      </div>
      <div class="col-lg-6">
        <p class="p-small">
          Copyright © 2021 SAASI or its
          <a href="www.affonelabs.net">affiliates</a>. All rights reserved.
        </p>
      </div>
      <!-- end of col -->
    </div>
    <!-- enf of row -->
  </div>
  <!-- end of container -->
</div>
<!-- end of copyright -->
<!-- end of copyright -->
