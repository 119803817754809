import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestaurantService } from './core/services/restaurant-service';

declare const $;
declare const Email
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Saasi';
  constructor(private snackBar: MatSnackBar,
              public restaurantService: RestaurantService) {}

  subscribe() {
    const email = document.getElementById('Newsletteremail')['value'];
    if (email.length) {
      Email.send({
        SecureToken : "e1fa0a2c-2917-400c-ab90-cb648a4df7a0",
        To : 'sales@grabqpons.com',
        From : 'no-reply.grabqpons.com@grabqpons.com',
        Subject : `Email from www.grabQpons.com by ${email}`,
        Body : `subscribe newsletter from ${email}`
      }).then(
        message => {
          if (message === 'OK') {
              this.addLead(email, email.split('@')[0], event, 'NewsLetter');
          } else {
              this.openSnackBar('Something wrong please try again!');
          }
        }
      );
    }
  }

  addLead(email, name, event, dataSource) {
    let body = {
        "email": [
          {
            "type": "personal",
            "value": email,
            "isPrimary": true
          }
        ],
        "emailOpted": true,
        "priority": 1,
        "leadName": name,
        "leadRole": "partner",
        "dataSource": dataSource
    };

    this.restaurantService.createLead(body).subscribe((res) => {
      if (res['su']) {
        this.openSnackBar('Your email successfully Sent! Thank you.');
      }
    }, error => {
        this.openSnackBar('Something wrong please try again!');
    })
  }

  openSnackBar(msg) {
    this.snackBar.open(msg || 'Someting went wrong!', 'ok', {
      duration: 3000,
    });
  }
}
