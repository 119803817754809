import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../services/token-service';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /*if (request.url.includes('scrap')) {
      request = request.clone({
        url: `http://localhost:3001/scrap`,
      });
      return next.handle(request);
    }*/

    request = request.clone({
      url: `${environment.envIP}:8000/api${request.url}`,
    });

    if (request.url.includes('oauth/bearer')) {
      return next.handle(request);
    }

    return this.injector
      .get(TokenService)
      .getBearerToken()
      .pipe(
        flatMap((bearerToken) => {
          return next.handle(
            request.clone({
              setHeaders: {
                Authorization: `Bearer ${bearerToken}`,
              },
            })
          );
        })
      );
  }
}
