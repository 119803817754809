<ng-template [ngIf]="verticalStepper" [ngIfElse]="horizontalStepper">
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step
      [stepControl]="stepperForm.controls['userInfo']"
      [optional]="isOptional"
    >
      <ng-template matStepLabel>Contact Information</ng-template>
      <ng-container *ngTemplateOutlet="step1"></ng-container>
    </mat-step>

    <mat-step
      [stepControl]="stepperForm.controls['businessInfo']"
      [optional]="isOptional"
    >
      <ng-template matStepLabel>Restaurant Info</ng-template>
      <ng-container *ngTemplateOutlet="step2"></ng-container>
    </mat-step>

    <mat-step
      [stepControl]="stepperForm.controls['websiteInfo']"
      [optional]="isOptional"
    >
      <ng-template matStepLabel>Website Info</ng-template>
      <ng-container *ngTemplateOutlet="step3"></ng-container>
    </mat-step>

    <mat-step [optional]="isOptional">
      <ng-template matStepLabel>Payment</ng-template>
      <ng-container *ngTemplateOutlet="step4"></ng-container>
    </mat-step>
  </mat-vertical-stepper>
</ng-template>

<ng-template #horizontalStepper>
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step
      [stepControl]="stepperForm.controls['userInfo']"
      [optional]="isOptional"
    >
      <ng-template matStepLabel>Contact Information</ng-template>
      <ng-container *ngTemplateOutlet="step1"></ng-container>
    </mat-step>

    <mat-step
      [stepControl]="stepperForm.controls['businessInfo']"
      [optional]="isOptional"
    >
      <ng-template matStepLabel>Restaurant Info</ng-template>
      <ng-container *ngTemplateOutlet="step2"></ng-container>
    </mat-step>

    <mat-step
      [stepControl]="stepperForm.controls['websiteInfo']"
      [optional]="isOptional"
    >
      <ng-template matStepLabel>Website Info</ng-template>
      <ng-container *ngTemplateOutlet="step3"></ng-container>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Payment</ng-template>
      <ng-container *ngTemplateOutlet="step4"></ng-container>
    </mat-step>
  </mat-horizontal-stepper>
</ng-template>

<ng-template #step1>
  <form [formGroup]="stepperForm">
    <div class="row justify-content-center spacer">
      <div class="col-md-6 col-lg-6">
        <mat-card>
          <p class="card-test">
            Please provide us an email address that you can be reached at. This
            will also be used to access your SAASI account information.
          </p>
          <div class="row" formGroupName="userInfo">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input
                  matInput
                  formControlName="email"
                  placeholder=""
                  required
                />
                <mat-error *ngIf="!stepperForm.get('userInfo.email').valid"
                  >Email is required</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <mat-card-actions
            class="card-action-btn"
            *ngIf="!showLoading; else spinner"
          >
            <button class="btn-solid-reg page-scroll" (click)="createUser()">
              Next
            </button>
          </mat-card-actions>
          <ng-template #spinner>
            <mat-card-actions class="card-action-btn">
              <mat-spinner class="center" [diameter]="40"></mat-spinner>
            </mat-card-actions>
          </ng-template>
        </mat-card>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #step2>
  <form [formGroup]="stepperForm">
    <div class="row justify-content-center spacer" formGroupName="businessInfo">
      <div class="col-md-8 col-lg-8">
        <mat-card>
          <p class="card-test">
            Tell us a little bit about your business! We want to make sure we
            are using the correct information so customers can find you.
          </p>
          <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4">
              <mat-form-field>
                <mat-label>Business Name</mat-label>
                <input
                  id="business-name-input"
                  matInput
                  formControlName="short_name"
                  (focus)="autocomplete('business-name-input', true)"
                  placeholder="Search Your Business"
                  required
                />
                <mat-error
                  *ngIf="!stepperForm.get('businessInfo.short_name').valid"
                >
                  Business Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4">
              <mat-form-field>
                <mat-label>address</mat-label>
                <input
                  matInput
                  formControlName="address"
                  placeholder="Please search your location"
                  required
                  id="address-input"
                  (focus)="autocomplete('address-input', false)"
                />
                <mat-error
                  *ngIf="!stepperForm.get('businessInfo.address').valid"
                >
                  Address is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4">
              <mat-form-field>
                <mat-label>City</mat-label>
                <input
                  matInput
                  formControlName="city"
                  placeholder="city"
                  required
                />
                <mat-error *ngIf="!stepperForm.get('businessInfo.city').valid">
                  Please enter valid email</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4">
              <mat-form-field>
                <mat-label>Cuisine Type</mat-label>
                <mat-select formControlName="type" placeholder="Cuisine Type">
                  <div *ngFor="let type of cuisinetype">
                    <mat-option [value]="type.id">
                      {{ type.value }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-8 col-lg-8">
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <mat-form-field>
                    <mat-select
                      formControlName="countryCode"
                      placeholder="country"
                    >
                      <div *ngFor="let code of countryData">
                        <mat-option [value]="code.code" *ngIf="code.code">
                          {{ code.code }}
                        </mat-option>
                      </div>
                    </mat-select>
                    <mat-error
                      *ngIf="!stepperForm.get('businessInfo.countryCode').valid"
                    >
                      Country is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <mat-form-field>
                    <mat-label>Phone</mat-label>
                    <input
                      matInput
                      formControlName="phone_work"
                      placeholder="Phone"
                    />
                    <mat-error
                      *ngIf="!stepperForm.get('businessInfo.phone_work').valid"
                      >Please Enter valid Phone
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <mat-form-field>
                    <mat-label>Fax</mat-label>
                    <input matInput formControlName="fax" placeholder="Fax" />
                    <mat-error
                      *ngIf="!stepperForm.get('businessInfo.fax').valid"
                      >Please Enter valid Fax
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4">
              <mat-form-field>
                <mat-label>Sales Tax</mat-label>
                <input matInput formControlName="salesTax" required />
                <span matSuffix> % </span>
                <mat-hint
                  >Please tell us the percentage for accurate pricing.
                </mat-hint>
                <mat-error
                  *ngIf="
                    stepperForm
                      .get('businessInfo.salesTax')
                      .hasError('required')
                  "
                >
                  Please enter a tax rate
                </mat-error>
                <mat-error
                  *ngIf="
                    stepperForm.get('businessInfo.salesTax').hasError('pattern')
                  "
                >
                  Tax Rate must be within 0 - 100%
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <mat-card-actions
            class="card-action-btn"
            *ngIf="!showLoading; else spinner"
          >
            <button class="btn-solid-reg page-scroll" (click)="goBack()">
              Previous
            </button>
            <button
              class="btn-solid-reg page-scroll"
              (click)="createbusiness()"
            >
              Next
            </button>
          </mat-card-actions>
          <ng-template #spinner>
            <mat-card-actions class="card-action-btn">
              <mat-spinner class="center" [diameter]="40"></mat-spinner>
            </mat-card-actions>
          </ng-template>
        </mat-card>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #step3>
  <form [formGroup]="stepperForm">
    <mat-accordion class="example-headers-align" formGroupName="websiteInfo">
      <mat-expansion-panel
        style="text-align: left"
        [hidden]="webSiteInfoPanelStep !== 0"
        [expanded]="webSiteInfoPanelStep === 0"
        (opened)="setWebsitePanel(0)"
        hideToggle
      >
        <div class="spacer">
          <mat-label
            >Would you like to preview your website/menu before it is made
            live?</mat-label
          >
          &nbsp;
          <mat-slide-toggle
            color="primary"
            formControlName="enableWebsite"
          ></mat-slide-toggle>
        </div>
        <ng-container
          *ngIf="stepperForm.get('websiteInfo.enableWebsite').value"
        >
          <br />
          <mat-label
            >Please select a template that you think suits your restaurant’s
            style.</mat-label
          >
          <div class="row spacer virtual-scroll">
            <div
              style="padding: 10px"
              class="col-sm-12 col-md-4 col-lg-4"
              *ngFor="let template of templates"
            >
              <mat-card (click)="selectTemplate(template)">
                <img
                  mat-card-image
                  style="height: 12rem"
                  [src]="template.imageUrl"
                  alt="template.name"
                />
                <mat-card-content>
                  <p>{{ template.name }}</p>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </ng-container>
        <mat-action-row>
          <button
            *ngIf="!stepperForm.get('websiteInfo.enableWebsite').value"
            class="btn-solid-reg page-scroll"
            (click)="verifyAndContinue()"
          >
            Skip
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <mat-expansion-panel
        style="text-align: left"
        [hidden]="webSiteInfoPanelStep !== 1"
        [expanded]="webSiteInfoPanelStep === 1"
        (opened)="setWebsitePanel(1)"
        hideToggle
      >
        <div class="spacer">
          <mat-label
            >Now, choose a color scheme that matches your restaurant’s branding
            and style.</mat-label
          >
        </div>
        <div class="row virtual-scroll">
          <div class="col-md-4 col-lg-4" *ngFor="let item of colorPalette">
            <div class="project-type-hover" (click)="selectColorPalette(item)">
              <div class="project-type-palette">
                <div class="pal-item-1" [style.background]="item.primary"></div>
                <div
                  class="pal-item-2"
                  [style.background]="item.secondary"
                ></div>
                <div
                  class="pal-item-3"
                  [style.background]="item.tertiary"
                ></div>
              </div>
              <div class="project-type-text text-center">
                <p class="project-type-subtext">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <mat-action-row>
          <button
            class="btn-solid-reg page-scroll"
            (click)="prevWebsitePanel()"
          >
            Previous
          </button>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel
        style="text-align: left"
        [hidden]="webSiteInfoPanelStep !== 2"
        [expanded]="webSiteInfoPanelStep === 2"
        (opened)="setWebsitePanel(2)"
        hideToggle
      >
        <ng-container
          *ngIf="stepperForm.get('websiteInfo.enableWebsite').value"
        >
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <mat-form-field class="example-chip-list">
                <mat-label
                  >What are some keywords you want your restaurant to
                  target?</mat-label
                >
                <mat-chip-list #chipList aria-label="tags selection">
                  <mat-chip
                    *ngFor="let tag of selectedTags"
                    [selectable]="'true'"
                    [removable]="'true'"
                    (removed)="removeTag(tag)"
                  >
                    {{ tag }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    placeholder="New Tag..."
                    #tagsInput
                    formControlName="tags"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addTag($event)"
                  />
                </mat-chip-list>
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="tagSelected($event)"
                >
                  <mat-option
                    *ngFor="let tag of filteredTags | async"
                    [value]="tag"
                  >
                    {{ tag }}
                  </mat-option>
                </mat-autocomplete>
                <mat-hint>
                  For example, a donut shop would want to target keywords such
                  as “donuts”, “Coffee” “Donut delivery” “Donuts and Coffee”. If
                  you specialize in something such as vegetarian, vegan, or
                  gluten-free be sure to write those in to. We need this
                  information to establish your SEO tags.</mat-hint
                >
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <mat-form-field>
                <mat-label>About Restaurant</mat-label>
                <textarea matInput formControlName="about"></textarea>
                <mat-error *ngIf="!stepperForm.get('websiteInfo.about').valid">
                  About restaurant is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <p class="card-test">
            Please enter any social media links and delivery partners you have.
            It makes it easier for customers to find and order from you if your
            various online presences are connected.
          </p>
          <p style="font: 400 14px/20px Roboto, 'Helvetica Neue', sans-serif">
            Social Links:
            <mat-button-toggle-group>
              <mat-button-toggle
                [disabled]="socialLinks.value.facebook"
                (click)="addsocialLink('facebook')"
              >
                <img
                  style="width: 30px"
                  src="https://static.grabqpons.com/default/assets/images/facebook.png"
                  alt="facebook"
                />
              </mat-button-toggle>
              <mat-button-toggle
                [disabled]="socialLinks.value.instagram"
                (click)="addsocialLink('instagram')"
              >
                <img
                  style="width: 30px"
                  src="https://static.grabqpons.com/default/assets/images/instagram.png"
                  alt="instagram"
                />
              </mat-button-toggle>
              <mat-button-toggle
                [disabled]="socialLinks.value.twitter"
                (click)="addsocialLink('twitter')"
              >
                <img
                  style="width: 30px"
                  src="https://static.grabqpons.com/default/assets/images/twitter.png"
                  alt="twitter"
                />
              </mat-button-toggle>
            </mat-button-toggle-group>
            &nbsp;&nbsp;&nbsp; Delivery Partners:
            <mat-button-toggle-group>
              <mat-button-toggle
                *ngFor="let item of deliveryPartnersData"
                [disabled]="deliveryPartners.controls[item.name]"
                (click)="addDeliveryPartners(item.name)"
              >
                <img
                  style="width: 50px"
                  [src]="item.imageUrl"
                  [alt]="item.name"
                />
              </mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          <div class="row" formGroupName="socialLinks">
            <div
              class="col-sm-12 col-md-4 col-lg-4"
              *ngIf="socialLinks.get('facebook')"
            >
              <mat-form-field>
                <mat-label>Facebook link</mat-label>
                <input matInput formControlName="facebook" />
                <mat-icon matSuffix (click)="removeSocialLink('facebook')"
                  >close</mat-icon
                >
              </mat-form-field>
            </div>
            <div
              class="col-sm-12 col-md-4 col-lg-4"
              *ngIf="socialLinks.get('instagram')"
            >
              <mat-form-field>
                <mat-label>Instagram link</mat-label>
                <input matInput formControlName="instagram" />
                <mat-icon matSuffix (click)="removeSocialLink('instagram')"
                  >close</mat-icon
                >
              </mat-form-field>
            </div>
            <div
              class="col-sm-12 col-md-4 col-lg-4"
              *ngIf="socialLinks.get('twitter')"
            >
              <mat-form-field>
                <mat-label>Twitter link</mat-label>
                <input matInput formControlName="twitter" />
                <mat-icon matSuffix (click)="removeSocialLink('twitter')"
                  >close</mat-icon
                >
              </mat-form-field>
            </div>
          </div>

          <div class="row" formGroupName="deliveryPartners">
            <ng-container *ngFor="let item of deliveryPartnersData">
              <div
                class="col-sm-12 col-md-4 col-lg-4"
                *ngIf="deliveryPartners.get(item.name)"
              >
                <mat-form-field>
                  <mat-label>{{ item.name }} link</mat-label>
                  <input matInput [formControlName]="item.name" />
                  <mat-icon
                    matSuffix
                    (click)="removeDeliveryPartners(item.name)"
                    >close</mat-icon
                  >
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <mat-action-row>
          <button
            class="btn-solid-reg page-scroll"
            (click)="prevWebsitePanel()"
          >
            Previous
          </button>

          <button
            *ngIf="
              stepperForm.get('websiteInfo.enableWebsite').value;
              else skipTheme
            "
            class="btn-solid-reg page-scroll"
            (click)="nextWebsitePanel()"
            [disabled]="!stepperForm.get('websiteInfo').valid"
          >
            Next
          </button>
          <ng-template #skipTheme>
            <button
              class="btn-solid-reg page-scroll"
              (click)="nextWebsitePanel()"
            >
              Continue
            </button>
          </ng-template>
        </mat-action-row>
      </mat-expansion-panel>

      <mat-expansion-panel
        style="text-align: left"
        [hidden]="webSiteInfoPanelStep !== 3"
        [expanded]="webSiteInfoPanelStep === 3"
        (opened)="setWebsitePanel(3)"
        hideToggle
      >
        <ng-container *ngIf="!stepsStatus.menuCreated; else menuExist">
          <div class="spacer">
            <mat-label class="spacer"
              >Would you like us to generate a digital menu for you using our
              Popular Services database?
            </mat-label>
            &nbsp;
            <mat-slide-toggle
              color="primary"
              formControlName="enableMenu"
            ></mat-slide-toggle>
          </div>
          <ng-container *ngIf="stepperForm.get('websiteInfo.enableMenu').value">
            <div class="spacer">
              <label id="example-radio-group-label">Pick your service</label>
              <mat-radio-group
                class="radio-group"
                [disabled]="showMenuLoading"
                formControlName="menuSource"
              >
                <mat-radio-button
                  class="radio-button"
                  *ngFor="let option of menuScrapeOptions"
                  [value]="option"
                >
                  {{ option }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-spinner
              *ngIf="showMenuLoading"
              class="center spacer"
              [diameter]="40"
            ></mat-spinner>
            <div
              class="mat-elevation-z8 spacer"
              *ngIf="menuData && menuData.length"
            >
              <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="Name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="Description">
                  <th mat-header-cell *matHeaderCellDef>Description</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.description }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Price">
                  <th mat-header-cell *matHeaderCellDef>Price</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.price }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="menuColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: menuColumns"></tr>
              </table>

              <mat-paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
            <ng-container *ngIf="!menuData && !showMenuLoading">
              <h5 class="text-center spacer">
                Menu not found at grubhub / groupons. Please upload menu from
                <a target="blank" href="www.partners.grabqpons.com"
                  >Grabqpons</a
                >
              </h5>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #menuExist>
          <h5 class="text-center spacer">
            Menu already exist. Please login to
            <a target="blank" href="www.partners.grabqpons.com">Grabqpons</a>
            and preview / edit menu
          </h5>
        </ng-template>

        <mat-action-row class="card-action-btn">
          <button
            class="btn-solid-reg page-scroll"
            (click)="prevWebsitePanel()"
          >
            Previous
          </button>
          <button
            *ngIf="
              stepperForm.get('websiteInfo.enableMenu').value &&
                menuData &&
                menuData.length;
              else menuSkip
            "
            class="btn-solid-reg page-scroll"
            (click)="nextWebsitePanel()"
          >
            Next
          </button>
          <ng-template #menuSkip>
            <button
              class="btn-solid-reg page-scroll"
              (click)="nextWebsitePanel()"
            >
              Skip
            </button>
          </ng-template>
        </mat-action-row>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="website"
        [hidden]="webSiteInfoPanelStep !== 4"
        [expanded]="webSiteInfoPanelStep === 4"
        (opened)="setWebsitePanel(4)"
        hideToggle
      >
        <div *ngIf="!verticalStepper; else elseBlock">
          <mat-button-toggle-group class="spacer" style="left: 40%">
            <mat-button-toggle (click)="websiteMobileView = 'smartphone'">
              <mat-icon>smartphone</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle (click)="websiteMobileView = 'tablet'">
              <mat-icon>tablet_mac</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle (click)="websiteMobileView = 'laptop'">
              <mat-icon>laptop</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <br />
          <div style="min-height: 500px">
            <div [class]="websiteMobileView">
              <div class="content" id="preview-website-content">
                <iframe
                  #iframe
                  style="width: 100%; border: none; height: 100%"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <ng-template #elseBlock>
          <iframe
            #iframe
            style="width: 100%; border: none; height: 100%"
          ></iframe>
        </ng-template>
        <mat-action-row class="card-action-btn">
          <button
            class="btn-solid-reg page-scroll"
            (click)="prevWebsitePanel()"
          >
            Previous
          </button>
          <button
            class="btn-solid-reg page-scroll"
            (click)="verifyAndContinue()"
          >
            Next
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</ng-template>

<ng-template #step4>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel
      class="payment-panel"
      [hidden]="paymentPanelStep !== 0"
      [expanded]="paymentPanelStep === 0"
      (opened)="setPaymentPanel(0)"
      hideToggle
    >
      <div *ngIf="!showLoading; else toeknGetSpinner" class="row spacer">
        <div
          class="col-sm-12 col-md-4 col-lg-4"
          *ngFor="let plan of subscriptionPlans"
        >
          <div class="card">
            <div class="card-body">
              <div class="card-title">{{ plan.name }}</div>
              <div class="price">
                <span class="currency">$</span
                ><span class="value">{{ plan.price }}</span>
              </div>
              <div class="frequency">{{ plan.frequency }}</div>
              <div class="divider"></div>
              <ul class="list-unstyled li-space-lg">
                <li class="media" *ngFor="let feature of plan.features">
                  <i *ngIf="feature.enable" class="fas fa-check"></i>
                  <i *ngIf="!feature.enable" class="fas fa-times"></i>
                  <div class="media-body">{{ feature.text }}</div>
                </li>
              </ul>
              <div class="button-wrapper">
                <button
                  class="btn-solid-reg page-scroll"
                  (click)="selectPlan(plan.planId)"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <!-- end of card -->
        </div>
      </div>
      <ng-template #toeknGetSpinner>
          <mat-spinner class="center" [diameter]="40"></mat-spinner>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="payment-panel"
      [hidden]="paymentPanelStep !== 1"
      [expanded]="paymentPanelStep === 1"
      (opened)="setPaymentPanel(1)"
      hideToggle
    >
      <mat-card class="domain-check-block">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-6 search-bar">
            <mat-form-field>
              <input
                #searchInput
                matInput
                placeholder="Search Domain Name"
                (keyup)="searchTerm($event.target.value)"
              />
            </mat-form-field>
          </div>
        </div>

        <div
          class="row justify-content-center spacer"
          *ngIf="!showDomainLoadin"
        >
          <div
            class="col-md-6 col-lg-6"
            *ngFor="let domain of object(searchResults)"
          >
            <ul class="list-group">
              <li
                *ngFor="let result of searchResults[domain] | slice: 0 - 10"
                class="domain-list-items list-group-item d-flex justify-content-between align-items-center"
              >
                {{ result.domain }} &nbsp; &nbsp; {{ result.period }} Year
                &nbsp; &nbsp;
                {{
                  result.price
                    | currency: businessControl.get("currencyCode").value
                }}
                <!--span class="badge badge-primary badge-pill"></span-->
                <span
                  (click)="selectAddOn(result)"
                  class="take-it"
                  style="color: green"
                  *ngIf="result.available"
                  ><a>TAKE IT</a></span
                >
                <span *ngIf="!result.available"><a>Not Available</a></span>
              </li>
            </ul>
          </div>
        </div>

        <div class="row justify-content-center spacer" *ngIf="showDomainLoadin">
          <div class="col-md-6 col-lg-6" *ngFor="let domain of [0, 1]">
            <ul class="list-group">
              <li
                *ngFor="
                  let result of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                    | slice: 0 - 10
                "
                class="domain-list-items list-group-item d-flex justify-content-between align-items-center"
              >
                <span class="loading">{{ searchInput.value }}</span>
              </li>
            </ul>
          </div>
        </div>

        <mat-card-actions class="card-action-btn">
          <button
            class="btn-solid-reg page-scroll"
            (click)="prevPaymentPanel()"
          >
            Previous
          </button>
          <button class="btn-solid-reg page-scroll" (click)="continuePayment()">
            Skip
          </button>
        </mat-card-actions>
      </mat-card>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="payment-panel"
      [hidden]="paymentPanelStep !== 2"
      [expanded]="paymentPanelStep === 2"
      (opened)="setPaymentPanel(2)"
      hideToggle
    >
      <mat-card>
        <ng-template [ngIf]="paymentStatus && paymentStatus.length">
          <div *ngIf="paymentProcessed; else showPaymentErr">
            <div class="alert alert-success" role="alert">
              {{ paymentStatus }}
            </div>
            <mat-label style="text-align: left" class="spacer"
              >Do you want to run any of these discount campaigns instantly on
              your website ?</mat-label
            >
            <div class="row spacer">
              <div class="col-md-6" *ngFor="let coupon of coupons">
                <mat-card matRipple
                [matRippleColor]="'#10d48e'" 
                class="example-card">
                  <img
                    mat-card-image
                    [src]="coupon.publicUrl"
                    [alt]="coupon.code"
                  />

                  <mat-card-actions>
                    <mat-checkbox
                      *ngIf="coupon.status === 'saved'"
                      color="primary"
                      (change)="selectCoupon($event.checked, coupon._id)"
                    >
                    </mat-checkbox>
                  </mat-card-actions>
                </mat-card>
              </div>
            </div>
            <mat-card-actions
              class="card-action-btn"
              *ngIf="!showLoading; else spinner"
            >
              <button
                *ngIf="selectedCoupons.length === 0"
                class="btn-solid-reg page-scroll"
                (click)="nextPaymentPanel()"
              >
                Skip
              </button>
              <button
                *ngIf="selectedCoupons.length > 0"
                class="btn-solid-reg page-scroll"
                (click)="publishCoupon()"
              >
                Publish
              </button>
            </mat-card-actions>
            <ng-template #spinner>
              <mat-card-actions class="card-action-btn">
                <mat-spinner class="center" [diameter]="40"></mat-spinner>
              </mat-card-actions>
            </ng-template>
          </div>
          <ng-template #showPaymentErr>
            <div class="alert alert-success" role="alert">
              {{ paymentStatus }}
            </div>
          </ng-template>
        </ng-template>
        <ng-template [ngIf]="!paymentProcessed">
          <div id="dropin-container"></div>
          <mat-card-actions class="card-action-btn">
            <button class="btn-solid-reg page-scroll" (click)="prevPaymentPanel()">
              Back
            </button>
            <button class="btn-solid-reg page-scroll" id="submit-button">
              Submit Payment
            </button>
          </mat-card-actions>
        </ng-template>
      </mat-card>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="payment-panel"
      [hidden]="paymentPanelStep !== 3"
      [expanded]="paymentPanelStep === 3"
      (opened)="setPaymentPanel(3)"
      hideToggle
    >
      <mat-card>
        <!--div class="row justify-content-center">
          <div class="col-md-6">
            <a target="_blank" href="https://appstore.grabqpons.com">
              <img alt="..." src="https://static.grabqpons.com/default/assets/images/app-store.png" style="height: 50;width: 150px;">
            </a>
          </div>
          <div class="col-md-6">
            <a target="_blank" href="https://playstore.grabqpons.com">
              <img alt="..." src="https://static.grabqpons.com/default/assets/images/play-store.png" style="height: 50;width: 150px;">
            </a>
          </div>
        </div-->
        <div class="appDownloadContainer">
          <h4>
            You can download our GrabQpons App for managing campaigns.
            <a color="primary" href="https://appstore.grabqpons.com" target="_blank">
              GrabQpons IOS
            </a>
            <a color="primary" href="https://playstore.grabqpons.com" target="_blank">
              GrabQpons Android
            </a>
          </h4>
          <div *ngIf="websiteUrl" class="containerHeader">URL</div>
          <div *ngIf="websiteUrl">
            <p>
              <a mat-button color="primary" [href]="websiteUrl" target="_blank">websiteUrl</a>
              <mat-icon (click)="openSnackBar('Copied to clipboard!')"
                class="linkIcon" [cdkCopyToClipboard]="websiteUrl" 
                color="primary">
                content_copy
              </mat-icon>
            </p>
          </div>
          <div class="containerHeader" *ngIf="qrCode">QR Code</div>
          <div *ngIf="qrCode">
            <p>This QR code link to your website when scanned with a mobile phone's camera app. you can share it with your customers, on social media websites, or print it out.</p>
            <img class="QRImg" [src]="qrCode" alt="">
            <button (click)="openSnackBar('Copied to clipboard!')" 
              [cdkCopyToClipboard]="qrCode" 
              mat-button color="primary">
              <mat-icon>content_copy</mat-icon> Copy Image
            </button>
              <a mat-button color="primary" [href]="qrCode" download="QRCODE">
                <mat-icon>file_download</mat-icon> Download Image
              </a>
          </div>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

