import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { RestaurantService } from '../core/services/restaurant-service';
import { MatStepper } from '@angular/material/stepper';
import { Observable, Subject, observable, forkJoin } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { MatDialog } from '@angular/material/dialog';
import { OtpCheckDialog } from '../otp-check/otp-check-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GlobalConstants } from '../common/global-constants';
import { finalize, map, startWith } from 'rxjs/operators';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface MenuElement {
  name: string;
  description: string;
  category: string;
  price: Number;
  type: string;
  classification: string;
  inStock: boolean;
  calories: Number;
  ingredients: string;
  categorySeq: number;
  itemSeq: number;
}

declare const google: any;
declare const braintree: any;

const daysMap = {
  0: 'SUN',
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI',
  6: 'SAT',
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  webSiteInfoPanelStep = 0;
  paymentPanelStep = 0;

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  stepsStatus = {
    userCreated: false,
    businessCreated: false,
    menuCreated: false,
  };

  stepperForm: FormGroup;
  isOptional = true;
  websiteMobileView = 'laptop';
  public componentForm = {
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
  };
  autocompletedHuors: any = [{}, {}, {}, {}, {}, {}, {}];
  selectedPlanId;
  menuData: MenuElement[] = [];
  menuColumns: string[] = ['Name', 'Description', 'Price'];
  dataSource;
  showMenuLoading = false;
  paymentProcessed = false;
  paymentStatus;
  verticalStepper = false;
  countryData = GlobalConstants.countryCodes;
  templates = GlobalConstants.templates;
  colorPalette = [];
  subscriptionPlans = GlobalConstants.subscriptions_USA;
  menuScrapeOptions = GlobalConstants.menuServices_USA;
  deliveryPartnersData = GlobalConstants.deliveryPartners_USA;
  searchTerm$ = new Subject<string>();
  searchResults = {};
  object = Object.keys;
  showDomainLoadin = false;
  selectedAddOn;
  showLoading = false;
  coupons = [];
  selectedCoupons = [];
  selectedTags: string[] = [];
  allTags: string[] = ['restaurant', 'food', 'order', 'online', 'instore'];
  filteredTags: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  qrCode;
  websiteUrl;
  cuisinetype = [
    {
      id: 'in',
      value: 'Indian',
    },
    {
      id: 'cn',
      value: 'Chinese',
    },
    {
      id: 'it',
      value: 'Italian',
    },
    {
      id: 'am',
      value: 'American',
    },
    {
      id: 'mx',
      value: 'Mexican',
    },
    {
      id: 'md',
      value: 'Mediterranean',
    },
    {
      id: 'kr',
      value: 'Korean',
    },
    {
      id: 'ml',
      value: 'Malaysian',
    },
    {
      id: 'jp',
      value: 'Japanese',
    },
    {
      id: 'eu',
      value: 'European',
    },
    {
      id: 'fr',
      value: 'French',
    },
    {
      id: 'au',
      value: 'Australian',
    },
    {
      id: 'vn',
      value: 'Vietnamese',
    },
    {
      id: 'th',
      value: 'Thai',
    },
    {
      id: 'me',
      value: 'Middle Eastern',
    },
    {
      id: 'gk',
      value: 'Greek',
    },
    {
      id: 'es',
      value: 'Spanish',
    },
    {
      id: 'ot',
      value: 'Other',
    },
  ];
  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private restaurantService: RestaurantService,
    private breakpointObserver: BreakpointObserver,
    private snackBar: MatSnackBar
  ) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.verticalStepper = result.matches;
      });

    const phoneRegex = '^[0-9]{10}$';
    const percentageRegex =
      '^(100(?:\\.00)?|0(?:\\.\\d?\\d)?|\\d?\\d(?:\\.\\d?\\d)?)$';
    this.stepperForm = this._formBuilder.group({
      userInfo: this._formBuilder.group({
        email: ['', Validators.email],
        phone: ['', [Validators.pattern(phoneRegex)]],
      }),
      businessInfo: this._formBuilder.group({
        name: ['', [Validators.required]],
        short_name: ['', [Validators.required]],
        address: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        postal: ['', Validators.required],
        phone_work: ['', [Validators.pattern(phoneRegex)]],
        fax: ['', [Validators.pattern(phoneRegex)]],
        country: ['', Validators.required],
        website: [''],
        category: ['fd', Validators.required],
        subCategory: ['rs', Validators.required],
        type: ['', Validators.required],
        averageTime: ['15', Validators.required],
        countryCode: ['', Validators.required],
        currencyCode: ['USD'],
        servicesOffered: ['pickup', Validators.required],
        ownerId: ['', Validators.required],
        salesTax: [
          '',
          [Validators.required, Validators.pattern(percentageRegex)],
        ],
        // 'enableMenu': [''],
      }),
      websiteInfo: this._formBuilder.group({
        enableWebsite: [false, Validators.required],
        selectedTemplate: ['', Validators.required],
        colorPalette: [
          {
            primary: '#193031',
            secondary: '#a5131c',
            tertiary: '#e41e35',
          },
          Validators.required,
        ],
        restaurantId: [''],
        tags: [''],
        about: [''],
        socialLinks: this._formBuilder.group({}),
        deliveryPartners: this._formBuilder.group({}),
        enableMenu: [''],
        menuSource: [''],
      }),
    });
    // this.websiteControl.patchValue({
    //   enableWebsite: false
    // });
    this.websiteControl.get('enableWebsite').valueChanges.subscribe((value) => {
      this.websiteControl.patchValue({
        selectedTemplate: '',
      });
      this.subscriptionPlans = [];
      this.webSiteInfoPanelStep = 0;
      this.paymentPanelStep = 0;
      
      if (!value) {
        if (this.businessControl.get('currencyCode').value === 'INR') {
          this.subscriptionPlans.push(GlobalConstants.subscriptions_India[0]);
        } else if (this.businessControl.get('currencyCode').value === 'USD') {
          this.subscriptionPlans.push(GlobalConstants.subscriptions_USA[0]);
        }
      } else {
        if (this.businessControl.get('currencyCode').value === 'INR') {
          this.subscriptionPlans = GlobalConstants.subscriptions_India.slice(1);
        } else if (this.businessControl.get('currencyCode').value === 'USD') {
          this.subscriptionPlans = GlobalConstants.subscriptions_USA.slice(1);
        }
      }
    });

    this.websiteControl.get('menuSource').valueChanges.subscribe((value) => {
      if (value.length) {
        this.scrapeMenu();
      }
    });

    this.websiteControl.get('enableMenu').valueChanges.subscribe((value) => {
      if (!value) {
        this.websiteControl.patchValue({
          menuSource: '',
        });
        this.menuData = [];
        this.dataSource = null;
      }
    });

    this.businessControl.get('currencyCode').valueChanges.subscribe((value) => {
      this.websiteControl.patchValue({
        enableWebsite: false
      });
      if (value === 'INR') {
        this.menuScrapeOptions = GlobalConstants.menuServices_India;
        // this.subscriptionPlans = GlobalConstants.subscriptions_India;
        this.deliveryPartnersData = GlobalConstants.deliveryPartners_India;
      } else if (value === 'USD') {
        this.menuScrapeOptions = GlobalConstants.menuServices_USA;
        // this.subscriptionPlans = GlobalConstants.subscriptions_USA;
        this.deliveryPartnersData = GlobalConstants.deliveryPartners_USA;
      }
    });

    this.filteredTags = this.websiteControl.get('tags').valueChanges.pipe(
      startWith(null),
      map((tags: string | null) =>
        tags ? this._filter(tags) : this.allTags.slice()
      )
    );

    this.businessControl.get('type').valueChanges.subscribe((value) => {
      const obj = this.cuisinetype.find(val => val.id === value);
      if(obj) {
        this.templates.unshift(
          this.templates.splice(
            this.templates.findIndex(elt => elt.tags.toLowerCase().includes(obj.value.toLowerCase()))
            , 1
          )[0]
        );
      }
    });

    this.restaurantService
      .search(this.searchTerm$, this.businessControl.get('currencyCode').value)
      .pipe(
        map((res) => {
          const domains =
            res && res['body'] ? res['body']['data']['availability'] : [];
          const prices =
            res && res['body'] ? res['body']['data']['prices'] : [];
          domains.map((element) => {
            const domainName = element.domain.split('.')[1];
            if (!this.searchResults[domainName]) {
              this.searchResults[domainName] = [];
            }
            element.price =
              prices[domainName][
                this.businessControl.get('currencyCode').value
              ];
            element.addOnKey = (
              domainName + this.businessControl.get('currencyCode').value
            ).toLowerCase();
            this.searchResults[domainName].push(element);
          });
          this.showDomainLoadin = false;
          return res;
        })
      )
      .subscribe();
  }

  ngOnInit() {}

  get userControl() {
    return this.stepperForm.get('userInfo') as FormGroup;
  }

  get businessControl() {
    return this.stepperForm.get('businessInfo') as FormGroup;
  }

  get websiteControl() {
    return this.stepperForm.get('websiteInfo') as FormGroup;
  }

  get websiteInfo() {
    const restaurant = this.businessControl.value;
    const websiteInfo = this.websiteControl.value;
    const deliveryInfo = this.deliveryPartners.value;
    let deliveryPartnersObj = [];
    this.deliveryPartnersData.forEach((element) => {
      if (deliveryInfo[element.name]) {
        deliveryPartnersObj.push({
          img: element.imageUrl,
          name: element.name,
          path: deliveryInfo[element.name],
        });
      }
    });
    return {
      inputs: {
        template: websiteInfo.selectedTemplate,
      },
      params: {
        restaurantName: restaurant.name,
        about: websiteInfo.about,
        city: restaurant.city,
        phone: restaurant.countryCode + restaurant.phone_work,
        address: restaurant.address,
        // socialLinks: this.socialLinks.value,
        primary: websiteInfo.colorPalette.primary,
        secondary: websiteInfo.colorPalette.secondary,
        facebook: this.socialLinks.value.facebook
          ? this.socialLinks.value.facebook
          : '',
        twitter: this.socialLinks.value.twitter
          ? this.socialLinks.value.twitter
          : '',
        instagram: this.socialLinks.value.instagram
          ? this.socialLinks.value.instagram
          : '',
        deliveryPartners: deliveryPartnersObj,
        tags: this.selectedTags.toString(),
        version: new Date().getTime(),
      },
    };
  }

  get socialLinks() {
    return this.websiteControl.get('socialLinks') as FormGroup;
  }

  get deliveryPartners() {
    return this.websiteControl.get('deliveryPartners') as FormGroup;
  }

  addDeliveryPartners(control): void {
    this.deliveryPartners.addControl(
      control,
      new FormControl('', Validators.required)
    );
  }

  removeDeliveryPartners(control) {
    this.deliveryPartners.removeControl(control);
  }

  addsocialLink(control): void {
    this.socialLinks.addControl(
      control,
      new FormControl('', Validators.required)
    );
  }

  removeSocialLink(control) {
    this.socialLinks.removeControl(control);
  }

  goBack() {
    this.paymentPanelStep = 0;
    this.webSiteInfoPanelStep = 0;
    this.stepper.previous();
  }

  goForward() {
    this.stepper.next();
  }

  createUser() {
    // step1
    if (!this.userControl.valid || this.stepsStatus.userCreated) {
      this.goForward();
      return;
    }
    const user = this.userControl.value;
    this.showLoading = true;
    this.restaurantService
      .businessInfo({
        email: user.email,
      })
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((res) => {
        if (res['success']) {
          const { owner, restaurant, location, menu } = res['body']['data'];
          if (owner) {
            this.stepsStatus.userCreated = true;
            this.businessControl.patchValue({
              ownerId: owner._id,
            });
            this.goForward();
          }

          if (restaurant && restaurant._id) {
            this.stepsStatus.businessCreated = true;
            this.websiteControl.patchValue({
              restaurantId: restaurant._id,
            });
            this.businessControl.patchValue({
              name: restaurant.name,
              short_name: restaurant.short_name,
              category: restaurant.category,
              subCategory: restaurant.subcategory,
              type: restaurant.cuisine_type,
              averageTime: restaurant.avgPickupTime,
              currencyCode: restaurant.currencyCode,
              servicesOffered: location.servicesOffered.type,
              address: location.address1,
              city: location.city,
              state: location.state,
              postal: location.zip,
              phone_work: location.phone_work
                ? location.phone_work.slice(-10)
                : '',
              fax: location.fax ? location.fax.slice(-10) : '',
              countryCode: location.phone_work
                ? location.phone_work.slice(0, -10)
                : '',
              salesTax: location.salesTax,
              country: location.country,
              website: location.website,
            });
            this.goForward();
          }

          if (menu && menu[0]['items']) {
            console.log('innnn');

            this.stepsStatus.menuCreated = true;
          }
          console.log(this.stepsStatus);
        }
      });
  }

  createbusiness() {
    // step 2
    if (!this.businessControl.valid || this.stepsStatus.businessCreated) {
      this.goForward(); // Go forward to show form Errors
      return;
    }
    const restaurant = this.businessControl.value;
    this.showLoading = true;
    this.createRestaurant(restaurant)
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe(
        (res) => {
          if (res['success']) {
            this.stepperForm.patchValue({
              websiteInfo: { restaurantId: res['body']['data']['_id'] },
            });
            this.goForward();
          }
        },
        (error) => {
          const { rootcause } = error.error.body ? error.error.body : '';
          this.openSnackBar(rootcause);
        }
      );
  }

  openSnackBar(msg) {
    this.snackBar.open(msg || 'Someting went wrong!', 'ok', {
      duration: 3000,
    });
  }

  scrapeMenu() {
    // step 3
    const restaurant = this.businessControl.value;
    const website = this.websiteControl.getRawValue();
    const body = {
      source: website.menuSource,
      businessName: restaurant.name,
      address:
        restaurant.address +
        ', ' +
        restaurant.city +
        ', ' +
        restaurant.state +
        ' ' +
        restaurant.postal +
        ', ' +
        restaurant.country,
    };
    this.showMenuLoading = true;
    this.restaurantService
      .searchMenu(body)
      .pipe(
        finalize(() => {
          this.showMenuLoading = false;
        })
      )
      .subscribe((res) => {
        this.menuData = res['body']['data']['menu'];
        this.dataSource = new MatTableDataSource<MenuElement>(this.menuData);
        setTimeout(() => (this.dataSource.paginator = this.paginator));
      });
  }

  verifyAndContinue() {
    if (!this.websiteControl.valid) {
      this.goForward(); // Go forward to show form Errors
      return;
    }

    this.goForward();

    /*const dialogRef = this.dialog.open(OtpCheckDialog, {
      width: '350px',
      data: { inputType: 'phone', verified: false},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.verified) {
        this.goForward();
      } else {
        // return throwError('Otp verification failed!');
      }
    });*/
  }

  previewWebsite() {
    if (!this.websiteControl.valid) {
      this.goForward(); // Go forward to show form Errors
      return;
    }
    const data = {
      menuItems: {
        restaurantId: this.websiteControl.value.restaurantId,
        items: this.stepsStatus.menuCreated ? [] : this.menuData,
      },
      websiteInfo: this.websiteInfo,
    };
    this.restaurantService.preview(data).subscribe((res) => {
      if (res['success']) {
        let doc =
          this.iframe.nativeElement.contentDocument ||
          this.iframe.nativeElement.contentWindow;
        doc.open();
        doc.write(unescape(res['body']['data']));
        doc.close();
        this.nextWebsitePanel();
        if (this.menuData.length) {
          this.stepsStatus.menuCreated = true;
        }
      }
    });
  }

  createRestaurant(restaurant): Observable<any> {
    const body = {
      city: restaurant.city,
      category: restaurant.category,
      subcategory: restaurant.subCategory,
      cuisine_type: restaurant.type,
      avgPickupTime: restaurant.averageTime,
      ownerId: restaurant.ownerId,
      name: restaurant.name,
      short_name: restaurant.short_name,
      locations: [
        {
          salesTax: restaurant.salesTax,
          address1: restaurant.address,
          city: restaurant.city,
          state: restaurant.state,
          zip: restaurant.postal,
          website: restaurant.website,
          type: 'py',
          country: restaurant.country,
          phone_work:
            restaurant.phone_work && restaurant.phone_work.length
              ? restaurant.countryCode + restaurant.phone_work
              : '',
          fax:
            restaurant.fax && restaurant.fax.length
              ? restaurant.countryCode + restaurant.fax
              : '',
          hours: this.autocompletedHuors[0]['day']
            ? this.autocompletedHuors
            : [],
          servicesOffered: { type: restaurant.servicesOffered },
        },
      ],
      currencyCode: restaurant.currencyCode,
      source: 'saasi',
      autoPublish: true,
      images: {
        banner: [
          'https://gqw.s3.amazonaws.com/assets/5cec1236579a000486851dac/banner-1562152548193.jpg',
          'https://gqw.s3.amazonaws.com/assets/5cec1236579a000486851dac/banner-1580899206500.jpg',
        ],
      },
    };

    return this.restaurantService.preBuild(body);
  }

  autocomplete(element, nameField) {
    const input = document.getElementById(element);
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        window.alert('No details available for input: ' + place.name + '');
        return;
      }
      let address = '';
      if (place.address_components) {
        address = [
          (place.address_components[0] &&
            place.address_components[0].short_name) ||
            '',
          (place.address_components[1] &&
            place.address_components[1].short_name) ||
            '',
          (place.address_components[2] &&
            place.address_components[2].short_name) ||
            '',
        ].join(' ');

        this.businessControl.get('address').reset();
        this.businessControl.get('city').reset();
        this.businessControl.get('state').reset();
        this.businessControl.get('postal').reset();
        this.businessControl.get('country').reset();
        this.businessControl.get('name').reset();
        this.businessControl.get('phone_work').reset();
        this.businessControl.get('countryCode').reset();
        this.autocompletedHuors = [{}, {}, {}, {}, {}, {}, {}];
        if (place.international_phone_number) {
          let phone = place.international_phone_number
            .replace(/[^a-zA-Z0-9]/g, '')
            .trim(); //
          this.businessControl.patchValue({
            countryCode: phone && '+' + phone.substr(0, phone.length - 10),
          });

          this.businessControl.patchValue({
            phone_work: phone && phone.substr(phone.length - 10),
          });
        }
        this.businessControl.patchValue({
          name: place.name,
        });

        if (nameField) {
          this.businessControl.patchValue({
            short_name: place.name,
          });
        }

        this.businessControl.patchValue({
          website: place.website,
        });

        this.businessControl.patchValue({
          address: address,
        });

        for (let i = 0; i < place.address_components.length; i++) {
          const addressType = place.address_components[i].types[0];
          if (this.componentForm[addressType]) {
            const val =
              place.address_components[i][this.componentForm[addressType]];
            if (addressType === 'locality') {
              this.businessControl.patchValue({
                city: val,
              });
            } else if (addressType === 'administrative_area_level_1') {
              this.businessControl.patchValue({
                state: val,
              });
            } else if (addressType === 'postal_code') {
              this.businessControl.patchValue({
                postal: val,
              });
            } else if (addressType === 'country') {
              this.businessControl.patchValue({
                country: val,
              });
              this.countryData.forEach((country) => {
                if (country['name'].toLowerCase() === val.toLowerCase()) {
                  this.businessControl.patchValue({
                    currencyCode: country['currency'],
                  });
                }
              });
            }
          }
        }
      }
      const opening_hours =
        place && place['opening_hours'] && place['opening_hours']['periods'];
      if (opening_hours.length > 0) {
        opening_hours.forEach((element) => {
          if (element['open'] && element['close']) {
            if (!this.autocompletedHuors[element.open.day]['day']) {
              this.autocompletedHuors[element.open.day]['day'] = '';
              this.autocompletedHuors[element.open.day]['hours'] = [];
            }

            if (element['close']['day'] !== element['open']['day']) {
              element.close.time = '2359';
              element.close.hours = '23';
              element.close.minutes = '59';
            }

            this.autocompletedHuors[element.open.day]['day'] =
              daysMap[element.open.day];
            this.autocompletedHuors[element.open.day]['hours'].push({
              open: [
                element.open.time.slice(0, 2),
                ':',
                element.open.time.slice(2),
              ].join(''),
              close: [
                element.close.time.slice(0, 2),
                ':',
                element.close.time.slice(2),
              ].join(''),
              dayPart: this.getDayPart(element),
            });
          }
        });

        let day = 0;
        this.autocompletedHuors.forEach((hours) => {
          if (!hours.day) {
            hours['day'] = daysMap[day];
            hours['hours'] = [];
            hours['hours'].push({
              open: '00:00',
              close: '00:00',
              dayPart: 'fd',
            });
          }
          day++;
        });
      }
    });
  }

  getDayPart(element) {
    if (element.open.hours >= 5 && element.close.hours <= 11) {
      return 'bf';
    } else if (element.open.hours >= 11 && element.close.hours <= 16) {
      return 'ln';
    } else if (element.open.hours >= 16 && element.close.hours <= 23) {
      return 'dn';
    } else {
      return 'fd';
    }
  }

  selectPlan(planId) {
    this.selectedPlanId = planId;
    this.nextPaymentPanel();
  }

  continuePayment() {
    const restaurant = this.businessControl.value;
    if (restaurant.currencyCode === 'INR') {
      this.initRazorPayment();
    } else {
      this.initBrainTreePayment();
    }
  }

  initBrainTreePayment() {
    const restaurant = this.businessControl.value;
    if (!restaurant.ownerId) {
      return;
    }

    this.showLoading = true;

    this.restaurantService
      .getPaymentToken(restaurant.ownerId).pipe(
        finalize(() => this.showLoading = false)
      )
      .subscribe((token) => {
        const clientToken = token['body']['data'];
        this.setPaymentPanel(2); // braintree panel
        braintree.dropin.create(
          {
            authorization: clientToken,
            container: '#dropin-container',
            paypal: {
              flow: 'vault',
            },
          },
          (createErr, instance) => {
            if (createErr) {
              console.error(createErr);
              return;
            } else {
              const button = document.querySelector('#submit-button');
              button.addEventListener('click', () => {
                instance.requestPaymentMethod(
                  (requestPaymentMethodErr, payload) => {
                    if (requestPaymentMethodErr) {
                      return;
                    } else {
                      const websiteInfo = this.websiteInfo;
                      const userInfo = this.userControl.value;
                      let body = {
                        websiteInfo,
                        subscriptionInfo: {
                          userId: restaurant.ownerId,
                          nonce: payload.nonce,
                          subscription: {
                            planId: this.selectedPlanId,
                            currency: restaurant.currencyCode,
                            account: {
                              accountCode: restaurant.ownerId,
                              email: userInfo.email,
                              firstName: userInfo.email.split('@')[0],
                              lastName: '',
                              billingInfo: {
                                address1: restaurant.address,
                                city: restaurant.city,
                                state: restaurant.state,
                                zip: restaurant.postal,
                                country: restaurant.country,
                              },
                            },
                          },
                        },
                      };
                      if (this.selectedAddOn) {
                        body['subscriptionInfo']['subscription']['addOns'] = {
                          add: [
                            {
                              inheritedFromId: this.selectedAddOn.addOnKey,
                              amount: this.selectedAddOn.price,
                              numberOfBillingCycles: 1,
                              quantity: 1,
                            },
                          ],
                        };
                        body['websiteInfo'][
                          'website'
                        ] = this.selectedAddOn.domain;
                      }
                      this.finalizePayment(body);
                    }
                  }
                );
              });
            }
          }
        );
      });
  }

  initRazorPayment() {
    const restaurant = this.businessControl.value;
    const websiteInfo = this.websiteInfo;
    let body = {
      websiteInfo,
      subscriptionInfo: {
        userId: restaurant.ownerId,
        subscription: {
          planId: this.selectedPlanId,
        },
      },
    };

    if (this.selectedAddOn) {
      body['subscriptionInfo']['subscription']['addons'] = [
        {
          item: {
            name: this.selectedAddOn.addOnKey,
            amount: Number(this.selectedAddOn.price),
            currency: restaurant.currencyCode,
          },
        },
      ];
      body['websiteInfo']['website'] = this.selectedAddOn.domain;
    }
    this.finalizePayment(body);
  }

  finalizePayment(body) {
    this.paymentStatus = null;
    this.restaurantService.finalize(body).subscribe((res) => {
      if (res['success']) {
        // console.log(res);
        this.paymentProcessed = true;
        this.paymentStatus = 'Transaction successfully completed!';
        this.coupons = res['body']['data']['coupons'];
        this.setPaymentPanel(2);
        this.websiteUrl = res['body']['data']['websiteDetails'];
        if (res['body']['data']['menu']) {
          this.qrCode = res['body']['data']['menu'][0]['qrCodeImage'];
        }
      } else {
        if (res['error']) {
          this.paymentProcessed = false;
          this.paymentStatus = res['error'].body.rootcause;
        }
      }
    });
  }

  setWebsitePanel(index: number) {
    this.webSiteInfoPanelStep = index;
  }

  nextWebsitePanel() {
    if (this.webSiteInfoPanelStep === 4) {
      return;
    }
    if (this.webSiteInfoPanelStep === 3) {
      if (!this.websiteControl.get('enableWebsite').value) {
        this.verifyAndContinue();
        // this.goForward();
        return;
      } else {
        this.previewWebsite();
      }
    }
    this.webSiteInfoPanelStep++;
  }

  prevWebsitePanel() {
    if (this.webSiteInfoPanelStep === 0) {
      return;
    }
    this.webSiteInfoPanelStep--;
  }

  setPaymentPanel(index: number) {
    this.paymentPanelStep = index;
  }

  nextPaymentPanel() {
    if (this.paymentPanelStep === 3) {
      return;
    }

    if (this.paymentPanelStep === 0 && !this.websiteControl.get('enableWebsite').value) {
      this.continuePayment();
      return;
    }
    
    this.paymentPanelStep++;

  }

  prevPaymentPanel() {
    if (this.paymentPanelStep === 0) {
      return;
    }
    this.paymentPanelStep--;

    if (this.paymentPanelStep === 1 && !this.websiteControl.get('enableWebsite').value) {
        this.paymentPanelStep--;
    }
  }

  selectTemplate(template) {
    this.websiteControl.patchValue({ selectedTemplate: template.template });
    this.colorPalette = template.colorPalette;
    this.nextWebsitePanel();
  }

  selectColorPalette(palette) {
    this.websiteControl.patchValue({ colorPalette: palette });
    this.nextWebsitePanel();
  }

  searchTerm(term) {
    this.searchTerm$.next(term);
    this.showDomainLoadin = true;
  }

  selectAddOn(addOn) {
    console.log(addOn);
    this.selectedAddOn = addOn;
    this.continuePayment();
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.selectedTags.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.websiteControl.get('tags').setValue(null);
  }

  removeTag(fruit: string): void {
    const index = this.selectedTags.indexOf(fruit);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedTags.push(event.option.viewValue);
    this.tagsInput.nativeElement.value = '';
    this.websiteControl.get('tags').setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(
      (fruit) => fruit.toLowerCase().indexOf(filterValue) === 0
    );
  }

  selectCoupon(checked, couponId) {
    if (checked) {
      this.selectedCoupons.push(couponId);
    } else {
      this.selectedCoupons = this.selectedCoupons.filter(
        (val) => val !== couponId
      );
    }
  }

  publishCoupon() {
    let couponArray = [];
    this.selectedCoupons.forEach((id) => {
      couponArray.push(
        this.restaurantService.updateCoupon(id, { status: 'published' })
      );
    });
    this.showLoading = true;
    forkJoin(couponArray)
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((res) => {
        this.goForward();
      });
  }
}
