/* Angular Modules */

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/*Interceptors*/
import { HttpHeaderInterceptor } from './interceptors/http-header-interceptor';

/*Services*/
import { RestaurantService } from './services/restaurant-service';
import { UserService } from './services/user-service';
import { TokenService } from './services/token-service';
import { PhoneService } from './services/phone-service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  entryComponents: [],
  providers: [
    RestaurantService,
    UserService,
    PhoneService,
    TokenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
