export class GlobalConstants {
  public static countryCodes = [
    {
      currency: 'USD',
      code: '+1',
      usd: '$',
      name: 'United States',
    },
    {
      currency: 'INR',
      code: '+91',
      inr: '₹',
      name: 'India',
    },
    {
      currency: 'NZD',
      code: '+64',
      inr: '$',
      name: 'New Zealand',
    },
  ];

  public static templates = [
    {
      name: 'Italian',
      template: 'italian.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/italian-pizza-orange.png',
      tags: 'italian, pizza',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: 'Sleek, Sophisticated, Mature, & Formal',
        },
        {
          primary: '#00cc47',
          secondary: '#041029',
          tertiary: '#0065ff',
          name: 'Sleek, Sophisticated, Mature, & Formal',
        }
        
      ],
    },
    {
      name: 'American Steakhouse',
      template: 'american-steakhouse.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/american-steakhouse.png',
      tags: 'american',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#00cc47',
          secondary: '#041029',
          tertiary: '#0065ff',
          name: '',
        },
        {
          primary: '#297afb',
          secondary: '#2898fb',
          tertiary: '#01d8fd',
          name: '',
        },
        {
          primary: '#353740',
          secondary: '#35bac1',
          tertiary: '#3ad2ad',
          name: '',
        },
        {
          primary: '#422db1',
          secondary: '#252780',
          tertiary: '#b061d0',
          name: '',
        },
        {
          primary: '#267b70',
          secondary: '#33a07f',
          tertiary: '#8bd746',
          name: '',
        },
      ],
    },
    {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: 'indian',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#00cc47',
          secondary: '#041029',
          tertiary: '#0065ff',
          name: '',
        },
        {
          primary: '#297afb',
          secondary: '#2898fb',
          tertiary: '#01d8fd',
          name: '',
        },
        {
          primary: '#353740',
          secondary: '#35bac1',
          tertiary: '#3ad2ad',
          name: '',
        },
        {
          primary: '#422db1',
          secondary: '#252780',
          tertiary: '#b061d0',
          name: '',
        },
        {
          primary: '#267b70',
          secondary: '#33a07f',
          tertiary: '#8bd746',
          name: '',
        },
      ],
    },
    {
      name: 'others-styel1.ejs',
      template: 'others-styel1.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: 'ittalian',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#E04644',
          secondary: '#ebdf1d',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#B7D968',
          secondary: 'rgb(170, 115, 0)',
          tertiary: '#000',
          name: '',
        },
      ],
    },
    {
      name: 'others-styel2.ejs',
      template: 'others-styel2.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: 'ittalian',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#2D3A0F',
          secondary: '#b576ad',
          tertiary: '#000',
          name: '',
        },
        {
          primary: 'rgb(31, 153, 32)',
          secondary: '#79CC10',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#4736CA',
          secondary: 'rgb(255, 218, 95)',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#4736CA',
          secondary: 'rgb(212, 110, 37)',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#4736CA',
          secondary: 'white',
          tertiary: '#000',
          name: '',
        },
      ],
    },
    {
      name: 'others-styel4.ejs',
      template: 'others-styel4.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: 'ittalian',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: '',
        },
        {
          primary: 'rgb(31, 153, 32)',
          secondary: '#79CC10',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#4736CA',
          secondary: 'rgb(212, 110, 37)',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#4736CA',
          secondary: 'white',
          tertiary: '#000',
          name: '',
        },
        {
          primary: 'rgb(4, 211, 254)',
          secondary: '#E30C0C',
          tertiary: '#000',
          name: '',
        },
      ],
    },
    {
      name: 'others-styel5.ejs',
      template: 'others-styel5.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: 'ittalian',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#2D3A0F',
          secondary: '#4736CA',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#B3FF00',
          secondary: '#0092FF',
          tertiary: '#000',
          name: '',
        },
      ],
    },

    {
      name: 'others-styel6.ejs',
      template: 'others-styel6.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: 'ittalian',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: '',
        },
      ],
    },
    {
      name: 'others-styel7.ejs',
      template: 'others-styel7.ejs',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: 'ittalian',
      colorPalette: [
        {
          primary: '#4736ca',
          secondary: '#27224f',
          tertiary: '#000',
          name: '',
        },
        {
          primary: '#7CCCE5',
          secondary: '#83129B',
          tertiary: '#000',
          name: '',
        },
      ],
    },
    
  ];

  public static subscriptions_USA = [
    {
      name: 'Starter',
      price: '$10.99',
      planId: '011',
      frequency: 'a month',
      features: [
        {
          enable: true,
          text: 'Accept Unlimited Orders via Digital QR Code Menu Page with Google Reviews',
        },
        {
          enable: true,
          text: 'Menu Management - Update, upsell Menu Items with discount setup',
        },
        {
          enable: true,
          text: 'Accept Order Payment In Store or Online and receive SMS/FAX, Email Order Confirmations',
        },
        {
          enable: false,
          text: 'Branded Website with Subdomain Hosting that links Delivery & Social Media channels',
        },
        {
          enable: false,
          text: 'Run Daily/Weekly Campaigns without any design skills, instantly share Auto ad Images on social media channels',
        },
      ],
    },
    {
      name: 'Rise ',
      price: '39.99',
      planId: '012',
      frequency: 'monthly',
      features: [
        {
          enable: true,
          text: 'Starter plans + ',
        },
        {
          enable: true,
          text: 'Branded Website with Subdomain Hosting that links Delivery & Social Media channels',
        },
        {
          enable: true,
          text: 'Run Daily/Weekly Campaigns without any design skills, instantly share Auto ad Images on social media channels',
        },
        {
          enable: false,
          text: 'Capture & Upload Manager Shift Change Checklist on Daily basis for Auditing. (Service aisle, Dining room etc) ',
        },
        {
          enable: false,
          text: 'Loyalty/Elite Programs for Top & Lost Customers based on Monthly/Weekly Analytics  ',
        },
      ],
    },
    {
      name: 'Scale',
      price: '$59.99 ',
      planId: '013',
      frequency: 'monthly',
      features: [
        {
          enable: true,
          text: 'Rise Plans +',
        },
        {
          enable: true,
          text: 'Capture & Upload Manager Shift Change Checklist on Daily basis for Auditing. (Service aisle, Dining room etc) ',
        },
        {
          enable: true,
          text: 'Loyalty/Elite Programs for Top & Lost Customers based on Monthly/Weekly Analytics  ',
        },
        {
          enable: true,
          text: 'Cloud/Gost Kitchen & Multiple virtual locations for food trucks',
        },
        {
          enable: false,
          text: 'Kitchen Printer Support (for Star models)',
        },
        {
          enable: false,
          text: 'Branded App Development, Merchant Account gateways Integration and On-demand delivery ',
        },
      ],
    },
    {
      name: 'Enterprise - Call for pricing',
      price: '--- ',
     
      frequency: 'monthly',
     
      
      features: [
        {
          enable: true,
          text: 'Scale plan +',
        },
        {
          enable: true,
          text: 'Kitchen Printer Support (for Star models)',
        },
        {
          enable: true,
          text: 'Branded App Development, Merchant Account gateways Integration and On-demand delivery ',
        },
        {
          enable: true,
          text: 'Social media Optimization & maintenance, Review Follow-ups & Video Marketing',
        },
        
      ],
    },
  ];

  public static subscriptions_India = [
    {
      name: 'BASIC',
      price: 'Free',
      planId: 'plan_GBt6Ph2qk9jEq8',
      frequency: '14 days trial',
      features: [
        {
          enable: true,
          text: 'Email Marketing Module',
        },
        {
          enable: true,
          text: 'User Control Management',
        },
        {
          enable: false,
          text: 'List Building And Cleaning',
        },
        {
          enable: false,
          text: 'Collected Data Reports',
        },
        {
          enable: false,
          text: 'Planning And Evaluation',
        },
      ],
    },
    {
      name: 'ADVANCED',
      price: '29.99',
      planId: 'plan_GBt6Ph2qk9jEq8',
      frequency: 'monthly',
      features: [
        {
          enable: true,
          text: 'Email Marketing Module',
        },
        {
          enable: true,
          text: 'User Control Management',
        },
        {
          enable: true,
          text: 'List Building And Cleaning',
        },
        {
          enable: false,
          text: 'Collected Data Reports',
        },
        {
          enable: false,
          text: 'Planning And Evaluation',
        },
      ],
    },
    {
      name: 'COMPLETE',
      price: '39.99',
      planId: 'plan_GBt6Ph2qk9jEq8',
      frequency: 'monthly',
      features: [
        {
          enable: true,
          text: 'Email Marketing Module',
        },
        {
          enable: true,
          text: 'User Control Management',
        },
        {
          enable: true,
          text: 'List Building And Cleaning',
        },
        {
          enable: true,
          text: 'Collected Data Reports',
        },
        {
          enable: true,
          text: 'Planning And Evaluation',
        },
      ],
    },
  ];

  public static menuServices_USA = ['GrubHub', 'Doordash'];

  public static menuServices_India = ['Swiggy'];

  public static deliveryPartners_USA = [
    {
      name: 'GrubHub',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/partners/grubhub.jpg',
    },
    {
      name: 'Doordash',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/partners/doordash.jpg',
    },
  ];

  public static deliveryPartners_India = [
    {
      name: 'Swiggy',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/partners/swiggy.jpg',
    },
    {
      name: 'Zomato',
      imageUrl:
        'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/partners/zomato.jpg',
    },
  ];
}
